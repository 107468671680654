/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://wmw6xaz5bzcxzmkgd2xeccy2zu.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:0e1c4d86-5c41-4a68-8986-cb56db1151df",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_2ctiTtRrP",
    "aws_user_pools_web_client_id": "7v38nj2he5e8a7si9g6cljtke5",
    "oauth": {
        "domain": "smalgyax-files-prod.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.smalgyax-files.org/,http://localhost:3000/,https://dev.d1nnyhcu0aulq5.amplifyapp.com/,https://prod.d1nnyhcu0aulq5.amplifyapp.com/",
        "redirectSignOut": "https://www.smalgyax-files.org/,http://localhost:3000/,https://dev.d1nnyhcu0aulq5.amplifyapp.com/,https://prod.d1nnyhcu0aulq5.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "AMAZON"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "haliamwaal-s3120918-prod",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
