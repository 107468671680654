/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types/AmplifyTypes";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getDocumentDetails = /* GraphQL */ `query GetDocumentDetails($id: ID!) {
  getDocumentDetails(id: $id) {
    id
    eng_title
    eng_description
    author {
      id
      name
      clan
      waa
      email
      createdAt
      updatedAt
      __typename
    }
    docOwner {
      id
      name
      clan
      waa
      email
      isAdmin
      createdAt
      updatedAt
      __typename
    }
    fileKey
    created
    updated
    type
    version
    box {
      id
      name
      waa
      owner {
        id
        name
        clan
        waa
        email
        isAdmin
        createdAt
        updatedAt
        __typename
      }
      defaultRole
      createdAt
      updatedAt
      xbiisOwnerId
      __typename
    }
    bc_title
    bc_description
    ak_title
    ak_description
    keywords
    createdAt
    updatedAt
    documentDetailsAuthorId
    documentDetailsDocOwnerId
    documentDetailsBoxId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDocumentDetailsQueryVariables,
  APITypes.GetDocumentDetailsQuery
>;
export const listDocumentDetails = /* GraphQL */ `query ListDocumentDetails(
  $filter: ModelDocumentDetailsFilterInput
  $limit: Int
  $nextToken: String
) {
  listDocumentDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      eng_title
      eng_description
      author {
        id
        name
        clan
        waa
        email
        createdAt
        updatedAt
        __typename
      }
      docOwner {
        id
        name
        clan
        waa
        email
        isAdmin
        createdAt
        updatedAt
        __typename
      }
      fileKey
      created
      updated
      type
      version
      box {
        id
        name
        waa
        defaultRole
        createdAt
        updatedAt
        xbiisOwnerId
        __typename
      }
      bc_title
      bc_description
      ak_title
      ak_description
      keywords
      createdAt
      updatedAt
      documentDetailsAuthorId
      documentDetailsDocOwnerId
      documentDetailsBoxId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDocumentDetailsQueryVariables,
  APITypes.ListDocumentDetailsQuery
>;
export const searchDocumentDetails = /* GraphQL */ `query SearchDocumentDetails(
  $filter: SearchableDocumentDetailsFilterInput
  $sort: [SearchableDocumentDetailsSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableDocumentDetailsAggregationInput]
) {
  searchDocumentDetails(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      eng_title
      eng_description
      author {
        id
        name
        clan
        waa
        email
        createdAt
        updatedAt
        __typename
      }
      docOwner {
        id
        name
        clan
        waa
        email
        isAdmin
        createdAt
        updatedAt
        __typename
      }
      fileKey
      created
      updated
      type
      version
      box {
        id
        name
        waa
        defaultRole
        createdAt
        updatedAt
        xbiisOwnerId
        __typename
      }
      bc_title
      bc_description
      ak_title
      ak_description
      keywords
      createdAt
      updatedAt
      documentDetailsAuthorId
      documentDetailsDocOwnerId
      documentDetailsBoxId
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchDocumentDetailsQueryVariables,
  APITypes.SearchDocumentDetailsQuery
>;
export const getAuthor = /* GraphQL */ `query GetAuthor($id: ID!) {
  getAuthor(id: $id) {
    id
    name
    clan
    waa
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAuthorQueryVariables, APITypes.GetAuthorQuery>;
export const listAuthors = /* GraphQL */ `query ListAuthors(
  $filter: ModelAuthorFilterInput
  $limit: Int
  $nextToken: String
) {
  listAuthors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      clan
      waa
      email
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAuthorsQueryVariables,
  APITypes.ListAuthorsQuery
>;
export const getXbiis = /* GraphQL */ `query GetXbiis($id: ID!) {
  getXbiis(id: $id) {
    id
    name
    waa
    owner {
      id
      name
      clan
      waa
      email
      isAdmin
      createdAt
      updatedAt
      __typename
    }
    defaultRole
    createdAt
    updatedAt
    xbiisOwnerId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetXbiisQueryVariables, APITypes.GetXbiisQuery>;
export const listXbiis = /* GraphQL */ `query ListXbiis(
  $filter: ModelXbiisFilterInput
  $limit: Int
  $nextToken: String
) {
  listXbiis(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      waa
      owner {
        id
        name
        clan
        waa
        email
        isAdmin
        createdAt
        updatedAt
        __typename
      }
      defaultRole
      createdAt
      updatedAt
      xbiisOwnerId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListXbiisQueryVariables, APITypes.ListXbiisQuery>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    name
    clan
    waa
    email
    isAdmin
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      clan
      waa
      email
      isAdmin
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getBoxUser = /* GraphQL */ `query GetBoxUser($id: ID!) {
  getBoxUser(id: $id) {
    id
    user {
      id
      name
      clan
      waa
      email
      isAdmin
      createdAt
      updatedAt
      __typename
    }
    box {
      id
      name
      waa
      owner {
        id
        name
        clan
        waa
        email
        isAdmin
        createdAt
        updatedAt
        __typename
      }
      defaultRole
      createdAt
      updatedAt
      xbiisOwnerId
      __typename
    }
    role
    createdAt
    updatedAt
    boxUserUserId
    boxUserBoxId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBoxUserQueryVariables,
  APITypes.GetBoxUserQuery
>;
export const listBoxUsers = /* GraphQL */ `query ListBoxUsers(
  $filter: ModelBoxUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listBoxUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user {
        id
        name
        clan
        waa
        email
        isAdmin
        createdAt
        updatedAt
        __typename
      }
      box {
        id
        name
        waa
        defaultRole
        createdAt
        updatedAt
        xbiisOwnerId
        __typename
      }
      role
      createdAt
      updatedAt
      boxUserUserId
      boxUserBoxId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBoxUsersQueryVariables,
  APITypes.ListBoxUsersQuery
>;
