/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types/AmplifyTypes";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createDocumentDetails = /* GraphQL */ `mutation CreateDocumentDetails(
  $input: CreateDocumentDetailsInput!
  $condition: ModelDocumentDetailsConditionInput
) {
  createDocumentDetails(input: $input, condition: $condition) {
    id
    eng_title
    eng_description
    author {
      id
      name
      clan
      waa
      email
      createdAt
      updatedAt
      __typename
    }
    docOwner {
      id
      name
      clan
      waa
      email
      isAdmin
      createdAt
      updatedAt
      __typename
    }
    fileKey
    created
    updated
    type
    version
    box {
      id
      name
      waa
      owner {
        id
        name
        clan
        waa
        email
        isAdmin
        createdAt
        updatedAt
        __typename
      }
      defaultRole
      createdAt
      updatedAt
      xbiisOwnerId
      __typename
    }
    bc_title
    bc_description
    ak_title
    ak_description
    keywords
    createdAt
    updatedAt
    documentDetailsAuthorId
    documentDetailsDocOwnerId
    documentDetailsBoxId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentDetailsMutationVariables,
  APITypes.CreateDocumentDetailsMutation
>;
export const updateDocumentDetails = /* GraphQL */ `mutation UpdateDocumentDetails(
  $input: UpdateDocumentDetailsInput!
  $condition: ModelDocumentDetailsConditionInput
) {
  updateDocumentDetails(input: $input, condition: $condition) {
    id
    eng_title
    eng_description
    author {
      id
      name
      clan
      waa
      email
      createdAt
      updatedAt
      __typename
    }
    docOwner {
      id
      name
      clan
      waa
      email
      isAdmin
      createdAt
      updatedAt
      __typename
    }
    fileKey
    created
    updated
    type
    version
    box {
      id
      name
      waa
      owner {
        id
        name
        clan
        waa
        email
        isAdmin
        createdAt
        updatedAt
        __typename
      }
      defaultRole
      createdAt
      updatedAt
      xbiisOwnerId
      __typename
    }
    bc_title
    bc_description
    ak_title
    ak_description
    keywords
    createdAt
    updatedAt
    documentDetailsAuthorId
    documentDetailsDocOwnerId
    documentDetailsBoxId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDocumentDetailsMutationVariables,
  APITypes.UpdateDocumentDetailsMutation
>;
export const deleteDocumentDetails = /* GraphQL */ `mutation DeleteDocumentDetails(
  $input: DeleteDocumentDetailsInput!
  $condition: ModelDocumentDetailsConditionInput
) {
  deleteDocumentDetails(input: $input, condition: $condition) {
    id
    eng_title
    eng_description
    author {
      id
      name
      clan
      waa
      email
      createdAt
      updatedAt
      __typename
    }
    docOwner {
      id
      name
      clan
      waa
      email
      isAdmin
      createdAt
      updatedAt
      __typename
    }
    fileKey
    created
    updated
    type
    version
    box {
      id
      name
      waa
      owner {
        id
        name
        clan
        waa
        email
        isAdmin
        createdAt
        updatedAt
        __typename
      }
      defaultRole
      createdAt
      updatedAt
      xbiisOwnerId
      __typename
    }
    bc_title
    bc_description
    ak_title
    ak_description
    keywords
    createdAt
    updatedAt
    documentDetailsAuthorId
    documentDetailsDocOwnerId
    documentDetailsBoxId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDocumentDetailsMutationVariables,
  APITypes.DeleteDocumentDetailsMutation
>;
export const createAuthor = /* GraphQL */ `mutation CreateAuthor(
  $input: CreateAuthorInput!
  $condition: ModelAuthorConditionInput
) {
  createAuthor(input: $input, condition: $condition) {
    id
    name
    clan
    waa
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAuthorMutationVariables,
  APITypes.CreateAuthorMutation
>;
export const updateAuthor = /* GraphQL */ `mutation UpdateAuthor(
  $input: UpdateAuthorInput!
  $condition: ModelAuthorConditionInput
) {
  updateAuthor(input: $input, condition: $condition) {
    id
    name
    clan
    waa
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAuthorMutationVariables,
  APITypes.UpdateAuthorMutation
>;
export const deleteAuthor = /* GraphQL */ `mutation DeleteAuthor(
  $input: DeleteAuthorInput!
  $condition: ModelAuthorConditionInput
) {
  deleteAuthor(input: $input, condition: $condition) {
    id
    name
    clan
    waa
    email
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAuthorMutationVariables,
  APITypes.DeleteAuthorMutation
>;
export const createXbiis = /* GraphQL */ `mutation CreateXbiis(
  $input: CreateXbiisInput!
  $condition: ModelXbiisConditionInput
) {
  createXbiis(input: $input, condition: $condition) {
    id
    name
    waa
    owner {
      id
      name
      clan
      waa
      email
      isAdmin
      createdAt
      updatedAt
      __typename
    }
    defaultRole
    createdAt
    updatedAt
    xbiisOwnerId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateXbiisMutationVariables,
  APITypes.CreateXbiisMutation
>;
export const updateXbiis = /* GraphQL */ `mutation UpdateXbiis(
  $input: UpdateXbiisInput!
  $condition: ModelXbiisConditionInput
) {
  updateXbiis(input: $input, condition: $condition) {
    id
    name
    waa
    owner {
      id
      name
      clan
      waa
      email
      isAdmin
      createdAt
      updatedAt
      __typename
    }
    defaultRole
    createdAt
    updatedAt
    xbiisOwnerId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateXbiisMutationVariables,
  APITypes.UpdateXbiisMutation
>;
export const deleteXbiis = /* GraphQL */ `mutation DeleteXbiis(
  $input: DeleteXbiisInput!
  $condition: ModelXbiisConditionInput
) {
  deleteXbiis(input: $input, condition: $condition) {
    id
    name
    waa
    owner {
      id
      name
      clan
      waa
      email
      isAdmin
      createdAt
      updatedAt
      __typename
    }
    defaultRole
    createdAt
    updatedAt
    xbiisOwnerId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteXbiisMutationVariables,
  APITypes.DeleteXbiisMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    name
    clan
    waa
    email
    isAdmin
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    name
    clan
    waa
    email
    isAdmin
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    name
    clan
    waa
    email
    isAdmin
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createBoxUser = /* GraphQL */ `mutation CreateBoxUser(
  $input: CreateBoxUserInput!
  $condition: ModelBoxUserConditionInput
) {
  createBoxUser(input: $input, condition: $condition) {
    id
    user {
      id
      name
      clan
      waa
      email
      isAdmin
      createdAt
      updatedAt
      __typename
    }
    box {
      id
      name
      waa
      owner {
        id
        name
        clan
        waa
        email
        isAdmin
        createdAt
        updatedAt
        __typename
      }
      defaultRole
      createdAt
      updatedAt
      xbiisOwnerId
      __typename
    }
    role
    createdAt
    updatedAt
    boxUserUserId
    boxUserBoxId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBoxUserMutationVariables,
  APITypes.CreateBoxUserMutation
>;
export const updateBoxUser = /* GraphQL */ `mutation UpdateBoxUser(
  $input: UpdateBoxUserInput!
  $condition: ModelBoxUserConditionInput
) {
  updateBoxUser(input: $input, condition: $condition) {
    id
    user {
      id
      name
      clan
      waa
      email
      isAdmin
      createdAt
      updatedAt
      __typename
    }
    box {
      id
      name
      waa
      owner {
        id
        name
        clan
        waa
        email
        isAdmin
        createdAt
        updatedAt
        __typename
      }
      defaultRole
      createdAt
      updatedAt
      xbiisOwnerId
      __typename
    }
    role
    createdAt
    updatedAt
    boxUserUserId
    boxUserBoxId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBoxUserMutationVariables,
  APITypes.UpdateBoxUserMutation
>;
export const deleteBoxUser = /* GraphQL */ `mutation DeleteBoxUser(
  $input: DeleteBoxUserInput!
  $condition: ModelBoxUserConditionInput
) {
  deleteBoxUser(input: $input, condition: $condition) {
    id
    user {
      id
      name
      clan
      waa
      email
      isAdmin
      createdAt
      updatedAt
      __typename
    }
    box {
      id
      name
      waa
      owner {
        id
        name
        clan
        waa
        email
        isAdmin
        createdAt
        updatedAt
        __typename
      }
      defaultRole
      createdAt
      updatedAt
      xbiisOwnerId
      __typename
    }
    role
    createdAt
    updatedAt
    boxUserUserId
    boxUserBoxId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBoxUserMutationVariables,
  APITypes.DeleteBoxUserMutation
>;
